<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Evaluation History Detail</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card" v-if="!loading">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-6">
                  <div class="row mb-3">
                    <label for="name" class="col-md-5 form-label">
                      Module Name
                    </label>
                    <div class="col-md-5">
                      <!-- <input type="text" id="name" name="name" class="form-control" placeholder="Program Name"
                                        v-model="approvalLists.program_name" disabled /> -->
                      <span>{{ evaluationHistory[0].module_name }}</span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="issued_date" class="col-md-5 form-label">
                      Batch
                    </label>
                    <div class="col-md-5">
                      <span>{{ evaluationHistory[0].batch }}</span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="issued_date" class="col-md-5 form-label">
                      Trainer
                    </label>
                    <div class="col-md-5">
                      <span
                        v-for="(trainer, index) in evaluationHistory[0].trainer"
                        :key="index"
                        >{{ trainer.name }}
                        <span
                          v-if="index + 1 < evaluationHistory[0].trainer.length"
                          >,
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row mb-3">
                    <label for="name" class="col-md-5 form-label">
                      Start Date
                    </label>
                    <div class="col-md-5">
                      <span>{{ evaluationHistory[0].start_date }}</span>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="name" class="col-md-5 form-label">
                      End Date
                    </label>
                    <div class="col-md-5">
                      <span>{{ evaluationHistory[0].end_date }}</span>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="name" class="col-md-5 form-label font-bold ">
                      Attendance Count
                    </label>
                    <div class="col-md-5">
                      <span>{{ attendanceCount }}</span>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="name" class="col-md-5 form-label">
                      Evaluation Count
                    </label>
                    <div class="col-md-5">
                      <span>{{ evaluationCriterias[0].scores.length }}</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="tableParent">
              <table class="table table-bordered criteriaTable">
                <thead>
                  <tr>
                    <th scope="col">Evaluation Criteria</th>
                    <th scope="col" class="text-center" :colspan="evaluationCriterias[0].scores.length">Score & Suggestions</th>
                  </tr>
                </thead>
                <tbody
                  
                >
                  <tr v-for="(item, index) in evaluationCriterias"
                  :key="index">
                    <td>{{ item.criteria_name }}</td>
                    <td class="text-center" v-for="(score, i) in item.scores" :key="i">{{ score }}</td>
                  </tr>
                 <tr>
                    <td>Suggestion</td>
                    <td class="text-center" v-for="(item, index) in evaluationHistory" :key="index">
                       {{ item.suggestion }}
                    </td>
                 </tr>
                </tbody>
              </table>
              </div>
              <!-- <div class="row mb-3" v-for="(item, index) in evaluationHistory.evaluation_results"
                                :key="index">
                                <label for="issued_date" class="col-md-3 form-label">
                                    {{ item.criteria_name }}
                                </label>
                                <div class="col-md-7">
                                    <span>{{ item.score }}</span>
                                </div>
                            </div> -->
              <!-- end row -->
            </form>
          </div>

          <div class="page-title-right">
            <router-link
              :to="{
                name: 'evaluation-history',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-4 float-end me-5"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {},
  data() {
    return {
      evaluationHistory: "",
      evaluationCriterias: [],
      trainers: [],
      attendanceCount: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
      loading: false,
    };
  },
  methods: {
    async getEvaluationHistoryById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/evaluation-history/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
          this.attendanceCount = result.attendanceCount;
          this.evaluationCriterias = result.criterias;
          this.evaluationHistory = result.historyDetail;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
      this.loading = false;
    },
  },
  created() {
    this.getEvaluationHistoryById();
  },
};
</script>

<style>
label {
  font-weight: 700;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
  cursor: not-allowed;
}
.criteriaTable {
    max-width: 1000px;
}
.tableParent{
    overflow-x: auto;
}
</style>